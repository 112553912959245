import React, { useState, useEffect } from "react";
import { Grid, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addCompetition,
  deleteCompetition,
  updateCompetition,
  resetCompetition,
} from "../../actions";
import {
  getDifficultyGroup,
  getOrganizations,
  getCollections,
  getTags,
  getCountries,
} from "../../functions/getData";
import { addCompetition as addCompetitionToDB } from "../../functions/postData";
import { formatDateTime, gradeOptions } from "../../functions/general";
import { isEmpty, showWarningSwal } from "../../functions/alert";
import { showNotification } from "../../functions/snackbar";
import ParticipatingCountries from "./ParticipatingCountries";
import Rounds from "./Rounds";
import DescriptionEditor from "../DescriptionEditor";
import NunitoText from "../general/NunitoText";
import ReusableTextField from "../general/ReusableTextField";
import ReusableDatePicker from "../general/ReusableDatePicker";
import ReusableButton from "../general/ReusableButton";
import ReusableToggle from "../general/ReusableToggle";
import ReusableAutocompleteWithID from "../general/ReusableAutocompleteWithID";
import TitleBar from "../general/TitleBar";
import AddPageWrapper from "../general/AddPageWrapper";
import InputContainer from "../general/InputContainer";
import { useSnackbar } from "notistack";
const competitionContainerStyle = {
  backgroundColor: "#F3F0F4",
  borderRadius: "0 0 12px 12px",
};
const radioStyle = { color: "#000" };
const buttonsContainerStyle = { marginRight: 40 };
const Competition = ({
  adding,
  count,
  competition,
  tagOptions,
  difficultyGroupOptions,
  organizationOptions,
  competitionOptions,
  collectionOptions,
  countriesOptions,
}) => {
  const [expandCompetition, setExpandCompetition] = useState(true);
  const [expandSetting, setExpandSetting] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    competitionName,
    alias,
    rerun,
    // rerunMarkingPreference,
    registrationOpen,
    registrationEnd,
    competitionStart,
    competitionEnd,
    difficultyGroup,
    format,
    gradesParticipating,
    instructions,
    rounds,
    competition_mode,
    tags,
    competitionOrganizations,
  } = competition;
  let history = useHistory();
  const dispatch = useDispatch();
  const update = (count, head, target, text, event) =>
    dispatch(updateCompetition(count, head, target, text, event));
  // const onChangeReRun = (count, head, target, text, event) => {
  //     dispatch(updateCompetition(count, head, target, text, event))
  //     dispatch(updateCompetition(count, 'settingText', 'rerunMarkingPreference', ''))
  //     if (rerun) {
  //         dispatch(updateCompetition(count, 'competitionBoolean', 'format', false))
  //     }
  // }
  // const onChangeMarkingPreference = (count, head, target, text, event) => {
  //     dispatch(updateCompetition(count, head, target, text, event))
  //     // Set all the partners, rounds and awards here
  // }
  const validateAlias = (alias) => {
    let result = "";
    for (let i = 0; i < alias.length; i++) {
      if (
        (alias[i].charCodeAt(0) >= 65 && alias[i].charCodeAt(0) <= 90) ||
        (alias[i].charCodeAt(0) >= 97 && alias[i].charCodeAt(0) <= 122) ||
        (alias[i].charCodeAt(0) == 32 && i !== 0 && i !== alias.length - 1) ||
        (alias[i].charCodeAt(0) >= 48 && alias[i].charCodeAt(0) <= 57) ||
        alias[i].charCodeAt(0) == 95
      ) {
        if (alias[i].charCodeAt(0) == 32) {
          result += "_";
        } else {
          result += alias[i];
        }
      }
    }
    return result;
  };
  const onSubmit = () => {
    console.log(validateAlias(alias));
    let msg = "";
    let arr = [
      { name: "Competition Name", state: competitionName },
      { name: "Alias", state: adding ? competitionName : validateAlias(alias) },
      { name: "Global Competition Start Date", state: competitionStart },
      { name: "Global Competition End Date", state: competitionEnd },
      { name: "Difficulty Group", state: difficultyGroup },
    ];
    if (isEmpty(arr.map((a) => a.state))) {
      arr
        .filter((a) => a.state === "")
        .forEach((a) => (msg += `Please fill in ${a.name}.<br>`));
    }
    if (format) {
      if (registrationOpen > registrationEnd) {
        msg +=
          "Global Registration End Date must be after or equal to Global Registration Start Date.<br>";
      }
      if (registrationEnd >= competitionStart) {
        msg +=
          "Global Registration End Date must be before Competition Start Date if Competition Format is Global.<br>";
      }
    } else {
      if (registrationOpen > competitionStart) {
        msg +=
          "Global Registration Start Date must be before or equal Competition Start Date if Competition Format is Local.<br>";
      }
    }
    if (competitionStart > competitionEnd) {
      msg +=
        "Global Competition End Date must be after Global Competition Start Date.<br>";
    }
    if (!gradesParticipating.length) {
      msg += "Please add at least one grades participating.<br>";
    }
    if (competitionOrganizations.length === 0) {
      msg +=
        "Please add at least one participating country to the competition.<br>";
    }
    for (let i = 0; i < competitionOrganizations.length; i++) {
      const partner = competitionOrganizations[i];
      let warningMessage = "";
      if (partner.organization_id === "") {
        warningMessage += `Country ${i + 1}'s organization is empty.<br>`;
      }
      if (partner.country_id === "") {
        warningMessage += `Country ${i + 1}'s country is empty.<br>`;
      }
      if (warningMessage) {
        msg += `<div style="text-align:center; font-weight: 800">Error in Country ${
          i + 1
        }</div>`;
        msg += `<div style="text-align:left">${warningMessage}</div>`;
        msg += "</div>";
      }
    }
    for (let i = 0; i < rounds.length; i++) {
      const round = rounds[i];
      let warningMessage = "";
      if (round.roundLabel === "")
        warningMessage += `Round ${i + 1}'s name is empty.<br>`;
      if (round.levels.length) {
        for (let j = 0; j < round.levels.length; j++) {
          const level = round.levels[j];
          if (level.level === "")
            warningMessage += `Round ${i + 1} Level ${
              j + 1
            }'s name is empty.<br>`;
          if (level.collection === "")
            warningMessage += `Round ${i + 1} Level ${
              j + 1
            }'s collection is empty.<br>`;
          if (level.grade.length === 0)
            warningMessage += `Round ${i + 1} Level ${
              j + 1
            }'s grades are empty.<br>`;
        }
      } else
        warningMessage += `Round ${i + 1} must have at least one level.<br>`;
      if (round.defaultAward === "")
        warningMessage += `Round ${i + 1}'s default award label is empty.<br>`;
      if (round.assignPoints && round.defaultPoints === "") {
        warningMessage += `Round ${
          i + 1
        }'s default award points cannot be empty `;
        warningMessage += `if you are assigning points manually for this round.<br>`;
      }
      if (warningMessage) {
        msg += `<div style="text-align:center; font-weight: 800">Error in Round ${
          i + 1
        }</div>`;
        msg += `<div style="text-align:left">${warningMessage}</div>`;
        msg += "</div>";
      }
    }
    if (msg) return showWarningSwal(msg);
    actuallySubmit();
  };
  const actuallySubmit = () => {
    let payload = {
      name: competitionName,
      alias: validateAlias(alias),
      tags: tags,
      global_registration_date: formatDateTime(registrationOpen, "/"),
      competition_start_date: formatDateTime(competitionStart, "/"),
      competition_end_date: formatDateTime(competitionEnd, "/"),
      difficulty_group_id: difficultyGroup,
      format: Number(format),
      competition_mode: Number(competition_mode),
      "re-run": 0,
      allowed_grades: gradesParticipating,
      // session_allow: 0,
      parent_competiton_id: null,
      organizations: competitionOrganizations.map((org) => ({
        organization_id: org.organization_id,
        country_id: org.country_id,
        edit_sessions: Number(org.allowEdit),
        competition_mode: 0,
        translate: JSON.stringify(org.translate),
      })),
      rounds: rounds.map((round) => {
        let obj = {
          name: round.roundLabel,
          round_type: Number(round.team),
          levels: round.levels.map((level) => ({
            name: level.level,
            collection_id: level.collection,
            grades: level.grade,
          })),
          award_type: Number(round.awardType),
          assign_award_points: Number(round.assignPoints),
          default_award_name: round.defaultAward,
          default_award_points: round.defaultPoints,
        };
        if (round.team) {
          obj.team_setting = round.teamOptions;
          obj.individual_points = Number(round.contributeToIndividualScore);
        }
        return obj;
      }),
    };
    console.log(payload, "pay");
    if (format)
      payload.global_registration_end_date = formatDateTime(
        registrationEnd,
        "/"
      );
    console.log(JSON.stringify(payload));
    addCompetitionToDB(payload)
      .then((d) => {
        if (d.status === 201) {
          showNotification(
            "success",
            d.message,
            enqueueSnackbar,
            closeSnackbar
          );
          dispatch(resetCompetition());
          // history.push('./competitions')
          history.push(`./editcompetition?id=${d.id}`);
        }
      })
      .catch((e) => console.log(e));
  };
  console.log(tags, "in add");
  return (
    <Grid container>
      <TitleBar
        title={`Competition`}
        state={expandCompetition}
        setState={setExpandCompetition}
        main
      >
        <div style={buttonsContainerStyle}>
          <ReusableButton
            text="New Competition"
            bgColor="#5E75C3"
            height={46}
            fontSize={16}
            marginLeft={20}
            onClick={() => dispatch(addCompetition(count + 1))}
            iconType="add"
          />
          {Boolean(count) && (
            <ReusableButton
              text="Delete Competition"
              bgColor="#E83D4D"
              height={46}
              fontSize={16}
              marginLeft={20}
              onClick={() => dispatch(deleteCompetition(count))}
              iconType="delete"
            />
          )}
        </div>
      </TitleBar>
      {expandCompetition && (
        <Grid container style={competitionContainerStyle}>
          <TitleBar
            title="Setting"
            state={expandSetting}
            setState={setExpandSetting}
          />
          {expandSetting && (
            <AddPageWrapper>
              <InputContainer
                first
                label="Competition Name*"
                field={
                  <ReusableTextField
                    type="default"
                    placeholder="Competition Name"
                    state={competitionName}
                    setState={update}
                    count={count}
                    head="settingText"
                    target="competitionName"
                    borderColor="#000"
                    required
                    onBlur
                    fullWidth
                  />
                }
              />
              <InputContainer
                label="Alias*"
                field={
                  <ReusableTextField
                    type="default"
                    placeholder="Alias*"
                    state={
                      adding
                        ? validateAlias(competitionName)
                        : validateAlias(alias)
                    }
                    setState={update}
                    count={count}
                    head="settingText"
                    target="alias"
                    borderColor="#000"
                    required
                    onBlur
                    fullWidth
                  />
                }
              />
              <InputContainer
                label="Competition Format*"
                field={
                  <ReusableToggle
                    type="reduxText"
                    width={250}
                    falseValue="Local"
                    trueValue="Global"
                    state={format}
                    setState={update}
                    count={count}
                    head="competitionBoolean"
                    target="format"
                    disabled={rerun}
                  />
                }
              />
              <InputContainer
                label={`Global Registration Start ${
                  format ? "and End " : ""
                }Date*`}
                field={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ReusableDatePicker
                      type="redux"
                      width={250}
                      state={registrationOpen}
                      setState={update}
                      count={count}
                      head="settingDate"
                      target="registrationOpen"
                      required
                    />
                    {Boolean(format) && (
                      <>
                        <NunitoText
                          value="TO"
                          fontSize={20}
                          fontWeight={600}
                          italic
                        />
                        <ReusableDatePicker
                          type="redux"
                          width={250}
                          state={registrationEnd}
                          setState={update}
                          count={count}
                          head="settingDate"
                          target="registrationEnd"
                          required
                        />
                      </>
                    )}
                  </div>
                }
              />
              <InputContainer
                label="Global Competition Start and End Date*"
                field={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ReusableDatePicker
                      type="redux"
                      width={250}
                      state={competitionStart}
                      setState={update}
                      count={count}
                      head="settingDate"
                      target="competitionStart"
                      required
                    />
                    <NunitoText
                      value="TO"
                      fontSize={20}
                      fontWeight={600}
                      italic
                    />
                    <ReusableDatePicker
                      type="redux"
                      width={250}
                      state={competitionEnd}
                      setState={update}
                      count={count}
                      head="settingDate"
                      target="competitionEnd"
                      required
                    />
                  </div>
                }
              />
              <InputContainer
                label="Competition Mode*"
                field={
                  <Grid container alignItems="center">
                    <RadioGroup
                      value={competition_mode}
                      onChange={(e) =>
                        update(
                          count,
                          "settingText",
                          "competition_mode",
                          e.target.value
                        )
                      }
                      row
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio style={radioStyle} />}
                        label="Paper Based"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio style={radioStyle} />}
                        label="Online"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio style={radioStyle} />}
                        label="Both"
                      />
                    </RadioGroup>
                    <ReusableButton
                      text="Clear Selection"
                      color="#000"
                      borderColor="#000"
                      fontSize={16}
                      marginRight={20}
                      onClick={() =>
                        update(count, "settingText", "competition_mode", "")
                      }
                    />
                  </Grid>
                }
              />
              <InputContainer
                label="Tags"
                field={
                  <ReusableAutocompleteWithID
                    type="default"
                    placeholder="Tags"
                    width={590}
                    multiple
                    bgColor="#FFF"
                    borderColor="#707070"
                    state={tags}
                    setState={update}
                    count={count}
                    head="settingMultiple"
                    target="tags"
                    marginTop={48}
                    border="#707070"
                    options={tagOptions.map((t) => ({
                      id: t.id,
                      option: t.name,
                    }))}
                  />
                }
              />
              <InputContainer
                label="Difficulty Group*"
                field={
                  <ReusableAutocompleteWithID
                    type="default"
                    placeholder="Difficulty Group"
                    width={590}
                    bgColor="#FFF"
                    borderColor="#707070"
                    state={difficultyGroup}
                    required
                    setState={update}
                    count={count}
                    head="settingText"
                    target="difficultyGroup"
                    options={difficultyGroupOptions.map((d) => ({
                      id: d.id,
                      option: d.name,
                    }))}
                  />
                }
              />
              <InputContainer
                label="Grades Participating*"
                field={
                  <ReusableAutocompleteWithID
                    type="default"
                    placeholder="Grades Participating"
                    width={590}
                    bgColor="#FFF"
                    borderColor="#707070"
                    state={gradesParticipating}
                    required
                    setState={update}
                    multiple
                    count={count}
                    head="settingMultiple"
                    target="gradesParticipating"
                    options={gradeOptions.map((g) => ({
                      id: g.id,
                      option: g.name,
                    }))}
                  />
                }
              />
              <InputContainer
                last
                label="Competition Instructions"
                field={
                  <DescriptionEditor
                    state={instructions}
                    setState={update}
                    height={400}
                    placeholder="Competition Instructions"
                    count={count}
                    head="settingText"
                    target="instructions"
                    readOnly={rerun}
                  />
                }
              />
              {/* <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <ReusableToggle type='reduxText' width={160} falseValue='No' trueValue='Yes'
                                    state={rerun} setState={onChangeReRun} count={count} head='competitionBoolean'
                                    target='rerun' />
                                {rerun && <ReusableAutocompleteWithID type='default' placeholder='Marking Preference'
                                    width={450} bgColor='#FFF'
                                    state={rerunMarkingPreference} setState={onChangeMarkingPreference} count={count}
                                    head='settingText' target='rerunMarkingPreference' borderColor='#707070'
                                    options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} />}
                            </div> */}
            </AddPageWrapper>
          )}
          <ParticipatingCountries
            count={count}
            competition={competition}
            organizationOptions={organizationOptions}
            countriesOptions={countriesOptions}
          />
          <Rounds
            count={count}
            competition={competition}
            collectionOptions={collectionOptions}
          />
          <Grid
            container
            justifyContent="flex-end"
            style={{ marginBlock: 20, marginRight: 30 }}
          >
            <ReusableButton
              text="Submit"
              bgColor="#5E75C3"
              fontSize={16}
              onClick={() => onSubmit()}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default function Competitions({ adding }) {
  const competitions = useSelector((state) => state.competition);
  // const [competitionOptions, setCompetitionOptions] = useState([])
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [CountriesOptions, setCountriesOptions] = useState();
  const [difficultyGroupOptions, setDifficultyGroupOptions] = useState([]);
  const competitionOptions = [];
  // const collectionOptions = []
  // const difficultyGroupOptions = []
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getTags("", signal).then((tag) => {
      setTagOptions(tag.map((t) => ({ id: t.filter_id, name: t.filter_name })));
    });
    getOrganizations("?status=active&limits=100", signal)
      .then((o) => {
        setOrganizationOptions(o.OrganizationLists.data);
      })
      .catch((e) => console.log(e));
    getCountries("?status=active&limits=100", signal)
      .then((country) => {
        console.log(country);
        setCountriesOptions(
          country.map((d) => ({ id: d.id, name: d.display_name }))
        );
      })
      .catch((e) => console.log(e));
    getDifficultyGroup("?status=active&limits=100", signal)
      .then((d) => {
        setDifficultyGroupOptions(
          d.TaskDifficultyGroupSLists.data.map((d) => ({
            id: d.id,
            name: d.name,
          }))
        );
      })
      .catch((e) => console.log(e));
    getCollections("?get_filter=onlynameandid", signal)
      .then((c) => {
        console.log(c);
        setCollectionOptions(c);
      })
      .catch((e) => console.log(e));

    return () => controller.abort();
  }, []);
  return competitions.map((competition, index) => (
    <Competition
      adding={adding}
      key={index}
      count={index}
      competition={competition}
      tagOptions={tagOptions}
      difficultyGroupOptions={difficultyGroupOptions}
      organizationOptions={organizationOptions}
      competitionOptions={competitionOptions}
      collectionOptions={collectionOptions}
      countriesOptions={CountriesOptions}
    />
  ));
}
